import Layout from "components/layout";
import SEO from "components/seo";
import React from "react";
import Denver from "components/locationIndexPages/Denver/index";

function index() {
  const title = "Denver Translation Services - Translation Agency";
  const description =
    "Looking for translation services in Denver, CO? Tomedes provides translation, localization, and interpretation solutions in 120+ languages with rapid delivery and 24/7 support.";
  const keywords = "Translation Services in Denver";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/denver-translation-services"
      />
      <Denver />
    </Layout>
  );
}

export default index;
