import React from "react";
import { Link } from "gatsby";

export default function TrustedTranslation({ backgroundCol, padding, FirstWhite, headColor, ColorHead, SecondWhite, Content, bottomContent, getStarted, getStartedLink, lastHeadColor, ThirdBlue, linkName }) {
  return (
    <>
      <section className={`${backgroundCol ? backgroundCol : "bg-darkBlue "} py-16  ${padding ? padding : null}  `}>
        <div className="max-w-6xl mx-auto  mlap:px-6 ">
          <div className="lap:grid lap:grid-cols-2  ">
            <div className="lap:pr-12">
              <h2 className="text-[35px] text-gray font-bold font-secondary leading-relaxed mb-4 lg:mb-0 ">
                {FirstWhite} <span className={`${headColor ? headColor : "text-lightBlue"} `}>{ColorHead} </span>
                {SecondWhite} <span className={`${lastHeadColor}`}>{ThirdBlue} </span>
              </h2>
            </div>

            <div className="">
              <p className="text-darkMist text-base leading-8 font-opensans" dangerouslySetInnerHTML={{__html: Content,}}></p>
              {bottomContent ? <p className="text-darkMist text-base mt-6 leading-8 font-opensans">{bottomContent}</p> : null}

              {getStarted ? (
                <div className="btn text-white learnmore max-w-max hover:text-orange pt-8">
                  <Link to={getStartedLink ? getStartedLink : "/"}>{linkName ? linkName : "GET STARTED"}</Link>
                  <div className="border-b bg-white"></div> {getStarted}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
