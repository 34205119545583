import React from "react";
import { Link } from "gatsby";

export default function CommonUse({
  title,
  para,
  data,
  backgroundCol,
  bottomBorderText,
  BBH,
  BBC,
  colorTitle,
  colorTitleText,
  hColor,
}) {
  return (
    <>
      <section
        className={`${backgroundCol ? backgroundCol : "bg-darkBlue"} py-20`}
      >
        <div className="max-w-6xl mx-auto ">
          <h3 className="text-center text-[#E6E6E6] w-10/12 mx-auto  text-[35px] font-semibold leading-normal pt-2 mb-3 slap:w-full mlg:w-9/12 slap:px-6 font-secondary">
            <span className={colorTitle ? colorTitle : "text-white"}>
              {colorTitleText}
            </span>{" "}
            {title}
          </h3>
          <p className="text-center text-[#BEBEBE] text-base font-opensans md:w-9/12 mtab:px-6 mx-auto pt-5 lg:px-4" dangerouslySetInnerHTML={{__html: para,}}>
          </p>
          <div className="border-t border-gray mt-8 mb-20 border-opacity-25 mmd:mx-20 mlg:mx-20 mtab:mx-6  "></div>
          {bottomBorderText ? (
            <div className="div">
              <h3 className="text-[26px] text-center font-secondary text-gray mt-10 mb-2 font-bold mx-6">
                {BBH} <span></span>
              </h3>
              <p className="text-center text-darkMist text-base font-opensans mb-12 md:w-10/12 mtab:px-6 mx-auto pt-5">
                {BBC}
              </p>
              {bottomBorderText}
            </div>
          ) : null}
          <div className="grid grid-cols-1 lg:grid-cols-2 lap:gap-4">
            {data
              ? data.map(({ title, detail, link, subTitle, link2 }) => (
                  <div className="lap:px-20 pb-10 slap:px-6 lg:w-[500px] relative lg:mb-7">
                    <h3
                      className={`${
                        hColor ? hColor : "text-orange"
                      } text-[22px] font-secondary font-bold mb-3`}
                    >
                      {title}{" "}
                      <span className="text-white">
                        {subTitle ? subTitle : null}
                      </span>
                    </h3>
                    <p className="text-darkMist font-secondary text-base leading-7 mb-3 lg:mb-7  lap:pr-4"  dangerouslySetInnerHTML={{__html: detail,}}>
                    </p>
                    {link ? (
                      <Link
                        to={link}
                        className="text-gray underline learnmore leading-7 hover:text-orange text-lg font-opensans lg:absolute bottom-0 lg:mt-5 "
                      >
                        GET STARTED
                      </Link>
                    ) : null}
                    {link2 ? (
                      <Link
                        to="/contactus.php"
                        className="text-gray underline learnmore leading-7 hover:text-orange text-lg font-opensans lg:absolute bottom-0 lg:mt-5"
                      >
                        CONTACT US
                      </Link>
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        </div>
      </section>
    </>
  );
}
