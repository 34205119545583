import React from "react";
import { Link } from "gatsby";

export default function SpeceficTranslation({
  backgroundCol,
  FirstWhite,
  FirstOrange,
  headColor,
  ColorHead,
  SecondWhite,
  Content,
  bottomContent,
  getStarted,
  getStartedLink,
  moreContent,
  linkName,
  lastLine,
  padding,
  link,
}) {
  return (
    <>
      <section className={`${backgroundCol ? backgroundCol : "bg-darkBlue "} ${padding ? padding : ""} bg-cover bg-no-repeat bg-center py-16`}>
        <div className="max-w-6xl mx-auto  mlap:px-6 ">
          <div className="lap:grid lap:grid-cols-1  ">
            <div className="lap:pr-12 mb-7">
              <h2 className="text-4xl text-gray inline-block md:pr-72 font-bold font-secondary leading-relaxed mb-4 lg:mb-0 ">
                <span className="text-orange">{FirstOrange}</span>
                {FirstWhite} <span className={`${headColor}`}>{ColorHead} </span>
                {SecondWhite}
              </h2>
            </div>

            <div className="">
              <p className="text-darkMist text-base leading-8 font-opensans">{Content}</p>
              {bottomContent ? <p className="text-darkMist text-base mt-6 leading-8 font-opensans">{bottomContent}</p> : null}
              {moreContent ? <p className="text-darkMist text-base mt-6 leading-8 font-opensans">{moreContent}</p> : null}
              {lastLine ? <p className="text-darkMist text-base mt-6 leading-8 font-opensans">{lastLine}</p> : null}
              {getStarted ? (
                <div className="btn text-white learnmore max-w-max hover:text-orange pt-8 learnmore-cn learnmore">
                  <Link to={getStartedLink ? getStartedLink : "/"}>{linkName ? linkName : "GET STARTED"}</Link>
                  <div className="border-b bg-white"></div> {getStarted}
                </div>
              ) : null}
            </div>
          </div>
          {link ? (
            link
          ) : (
            <Link to="/language-translation-service.php" className="inline-block uppercase underline text-white hover:text-orange text-[18px] font-normal mt-5">
              SEE OUR SUPPORTED LANGUAGES
            </Link>
          )}
        </div>
      </section>
    </>
  );
}
